import React from 'react'
import StickyWrapper from '../components/shared/StickyWrapper'
import SEO from '../components/seo'

import Top from '../components/about/Top'
import Accordion from '../components/about/Accordion'
// import VideoSection from '../components/about/VideoSection'
import Workshops from '../components/about/Workshops'
// import Partners from '../components/about/Partners'

const About = props => {
  const data = props.data.dis.pageAbout

  const {
    lead,
    mainText,
    group1Title,
    group1,
    group2Title,
    group2,
    // fullVideo,
    // videoLoop,
    // videoLoopSource,
    // fullVideoSource,
    workshopsTitle,
    workshopsLead,
    workshopsText,
    workshops,
    // partners,
    // partnersHeading,
    seoDescription,
    seoImage,
    seoTitle,
  } = data

  return (
    <div>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage && seoImage.url}
      />
      <StickyWrapper>
        <Top lead={lead} mainText={mainText} />
      </StickyWrapper>

      <div id="d-01"></div>
      <Accordion title={group1Title} els={group1} />

      <div id="l-01"></div>
      <Accordion title={group2Title} els={group2} light />

      {
        // TODO: uncomment when nexessary
        // <StickyWrapper scrollTo>
        // <VideoSection fullVideo={fullVideoSource} videoLoop={videoLoopSource} />
        // </StickyWrapper>
      }
      {
        // TODO: when industry partners are back - wrap Workshops with sticky
        // <StickyWrapper>
        // </StickyWrapper>
      }
      <div id="d-02"></div>
      <Workshops
        className="dark-section"
        data={workshops}
        title={workshopsTitle}
        lead={workshopsLead}
        text={workshopsText}
      />
      <div id="l-02"></div>
      {
        // TODO: uncomment when necessary
        // <Partners data={partners} title={partnersHeading} />
      }
      <div id="d-03"></div>
      <div id="l-03"></div>
      <div id="d-04"></div>
      <div id="l-04"></div>
    </div>
  )
}

export const query = graphql`
  query AboutPageQuery {
    dis {
      pageAbout(where: { id: "cki8xqge03cx50a03e8bwh13j" }) {
        fullVideo {
          url
          mimeType
        }
        videoLoopSource
        fullVideoSource
        group1Title
        group2Title
        group1 {
          name
          image {
            handle
            height
            width
          }
          position
          text {
            html
            markdown
          }
        }
        group2 {
          name
          image {
            handle
            height
            width
          }
          position
          text {
            html
            markdown
          }
        }
        lead
        mainText
        seoDescription
        seoImage {
          url
        }
        seoTitle
        title
        videoLoop {
          mimeType
          url
        }
        workshopsLead
        workshopsTitle
        workshopsText
        workshops {
          image {
            height
            handle
            width
          }
          about
          location
          imageCaption
          name
          video {
            url
            mimeType
          }
          videoSource
        }
        partners {
          name
          image {
            width
            height
            handle
          }
          about
          websiteUrl
          websiteLabel
          socialLabel
          socialUrl
        }
        partnersHeading
      }
    }
  }
`

export default About
