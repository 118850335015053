import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { useMediaQuery } from 'react-responsive'

const Wrapper = styled.div`
  position: ${({ noSticky }) => (noSticky ? 'static' : 'sticky')};
  transform: translateZ(0);
  top: ${({ noMob, t }) => (noMob ? 'auto' : `${t}px`)};
  z-index: 1;
  backface-visibility: hidden;

  @media only screen and (min-width: 1024px) {
    top: ${({ t }) => t}px;
    z-index: 1;
  }
`
const THRESHOLD = [0.5, 1]

const StickyWrapper = ({
  children,
  noMob,
  scrollTo,
  noSticky,
  scrollOffset = 0,
}) => {
  const [top, setTop] = useState(0)
  const el = useRef(null)
  const scrollTimeout = useRef(null)
  const { ref, inView, entry } = useInView({ threshold: THRESHOLD })
  const isDt = useMediaQuery({
    query: '(min-width: 1024px)',
  })

  const currTop = () => {
    setTop(window.innerHeight - el.current.offsetHeight)
  }

  const checkImages = arr => {
    if (arr.every(item => item.offsetHeight > 0)) {
      currTop()
    } else {
      setTimeout(() => {
        checkImages(arr)
      }, 100)
    }
  }

  const handleScroll = e => {
    clearTimeout(scrollTimeout.current)

    if (inView) {
      if (
        el.current?.nextSibling?.getBoundingClientRect()?.top >
          window.innerHeight / 2 &&
        el.current?.nextSibling?.getBoundingClientRect()?.top <
          window.innerHeight * 1.5 &&
        document.getElementById('footer').getBoundingClientRect()?.top -
          window.innerHeight >
          0
      ) {
        scrollTimeout.current = setTimeout(() => {
          document.body.scroll({
            top:
              e.target.scrollTop +
              el.current?.nextSibling?.getBoundingClientRect()?.top -
              window.innerHeight +
              scrollOffset,
            behavior: 'smooth',
          })
        }, 1000)
      }
    }
  }

  useEffect(() => {
    if (scrollTo && !(noMob && !isDt)) {
      document.body.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scrollTo && !(noMob && !isDt)) {
        document.body.removeEventListener('scroll', handleScroll)
      }
    }
  }, [inView, isDt, noMob])

  useEffect(() => {
    window.addEventListener('resize', currTop)
    return () => {
      window.removeEventListener('resize', currTop)
    }
  }, [])

  useEffect(() => {
    currTop()

    if (el.current) {
      const images = Array.from(el.current.getElementsByTagName('img'))
      if (images.length > 0) {
        checkImages(images)
      }
    }
  }, [el.current])

  return (
    <Wrapper ref={el} t={top} noMob={noMob} noSticky={noSticky}>
      <div ref={ref}>{children}</div>
    </Wrapper>
  )
}

export default StickyWrapper
