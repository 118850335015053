import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import H from '../styled/Heading'
import W from '../styled/Wrapper'

import AccItem from './AccItem'

const Wrapper = styled.section`
  background-color: ${({ theme, light }) =>
    light ? theme.white : theme.black};
  color: ${({ theme, light }) => (light ? theme.black : theme.white)};
  padding-top: 115px;
  position: sticky;
  transform: translateZ(0);
  top: auto;
  z-index: 2;

  @media only screen and (min-width: 1024px) {
    padding-top: 150px;
    z-index: 1;
  }

  &:after {
    content: '';
    height: 1px;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    box-shadow: ${({ light, theme }) =>
      light
        ? `0px 0px 20px 5px ${theme.blackEvenLessOpaque}`
        : `0px 0px 30px 20px ${theme.black}`};
  }
`

const TitleWrapper = styled(W)`
  position: relative;

  &:after {
    content: '';
    height: 1px;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    /* box-shadow: ${({ theme }) => `0px 0px 20px 5px ${theme.blackOpaque}`}; */
  }
`

const Title = styled(H)`
  text-align: center;
  padding-bottom: 115px;

  @media only screen and (min-width: 1024px) {
    padding-bottom: 140px;
  }
`

const List = styled.div`
  list-style: none;
  margin: 0;
`

const Accordion = ({ title, els, light }) => {
  const [open, setOpen] = useState([])

  useEffect(() => {
    if (els && els.length > 0) {
      let states = []
      els.forEach((element, i) => {
        states.push(false)
      })

      setOpen(states)
    }
  }, [els])

  const update = (index, value) => {
    let states = []

    if (els && els.length > 0) {
      els.forEach((element, i) => {
        states.push(value ? i === index : false)
      })

      setOpen(states)
    }
  }
  return (
    <Wrapper light={light}>
      <TitleWrapper>
        <Title size={1}>{title}</Title>
      </TitleWrapper>

      <List>
        {els.map((item, i) => {
          return (
            <AccItem
              key={i}
              data={item}
              open={false}
              index={i}
              update={update}
              open={open[i] && open[i]}
              light={light}
            />
          )
        })}
      </List>
    </Wrapper>
  )
}

export default Accordion
