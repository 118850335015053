import React, { useState } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import { useMediaQuery } from 'react-responsive'
import GraphImg from 'graphcms-image'

import W from '../styled/Wrapper'
import RichText from '../shared/RichText'

const Wrapper = styled(({ light, ...rest }) => <animated.div {...rest} />)`
  padding-top: 60px;
  padding-bottom: 90px;
  position: relative;
  background-color: ${({ theme, light }) =>
    light ? theme.white : theme.black};
  cursor: pointer;

  &:after {
    content: '';
    height: 100vw;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    border-top: ${({ theme, light }) =>
      light ? `0` : `1px solid ${theme.whiteOpaque}`};
    box-shadow: ${({ light, theme }) =>
      light
        ? `0px 0px 20px 5px ${theme.blackEvenLessOpaque}`
        : `0px 0px 30px 20px ${theme.black}`};
  }
`

const Inner = styled.div`
  background-color: ${({ theme, light }) =>
    light ? theme.white : theme.black};
`

const Sizer = styled(W)`
  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    column-gap: 40px;
    grid-template-areas: 'name text image';
  }
`

const ClickableWrapper = styled.div`
  cursor: pointer;

  @media only screen and (min-width: 1024px) {
    grid-area: name;
  }
`

const Name = styled.h3`
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 1.94px;
  text-transform: uppercase;
  margin-bottom: 13px;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 10px;
  }

  @media only screen and (min-width: 1100px) {
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 2px;
  }
`
const Position = styled.div`
  font-family: ${({ theme }) => theme.fontSansMedium};
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 46px;
  text-transform: uppercase;
`

const ImageSizer = styled.div`
  cursor: pointer;

  @media only screen and (min-width: 1024px) {
    grid-area: image;
    text-align: right;
  }
`

const Image = styled(animated.div)`
  margin-bottom: 40px;
  order: 2;

  @media only screen and (min-width: 1024px) {
    margin-left: auto;
  }
`

const TextWrapper = styled.div`
  @media only screen and (min-width: 1024px) {
    grid-area: text;
  }
`

const TextInner = styled.div`
  max-width: 568px;
`

const AccItem = ({ data, open, update, index, light }) => {
  const { name, position, image, text } = data

  const [hovered, setHovered] = useState(false)
  const [elRef, { height }] = useMeasure({ polyfill: ResizeObserver })
  const [wrapRef, { height: wrapHeight }] = useMeasure({ polyfill: ResizeObserver })
  const [imgRef, { height: imgHeight }] = useMeasure({
    polyfill: ResizeObserver,
  })
  const isDt = useMediaQuery({
    query: '(min-width: 1024px)',
  })

  const isMob = useMediaQuery({
    query: '(max-width: 767px)',
  })

  const springProps = useSpring({
    height: open
      ? isDt
        ? height + 110
        : wrapHeight + imgHeight * 0.3 
      : isDt
      ? 100
      : (light ? 240 : 270),
    // height: open ? height + imgHeight * 1.3 : isDt ? 100 : 270,
    transform: hovered || open ? 'translateY(-15px)' : 'translateY(0px)',
    config: { mass: 2, tension: 2000, friction: 175 },
  })

  const imageSpringProps = useSpring({
    width: open
      ? isMob || isDt
        ? '100%'
        : '50%'
      : !isMob && !isDt
      ? '35%'
      : '65%',
    config: { mass: 2, tension: 2000, friction: 200 },
  })

  return (
    <Wrapper
      style={{ ...springProps }}
      light={light}
      open={open}
      onClick={() => update(index, !open)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Inner hovered={hovered} light={light}>
        <Sizer ref={wrapRef}>
          <ClickableWrapper onClick={() => update(index, !open)}>
            <Name>{name}</Name>
            <Position>{position}</Position>
          </ClickableWrapper>
          {image && (
            <ImageSizer onClick={() => update(index, !open)}>
              <Image style={{ ...imageSpringProps }} ref={imgRef}>
                <GraphImg
                  image={image}
                  backgroundColor={'transparent'}
                  maxWidth={400}
                  blurryPlaceholder={false}
                />
              </Image>
            </ImageSizer>
          )}
          <TextWrapper ref={elRef}>
            <TextInner>
              <RichText
                content={text.html}
                markdown={text.markdown}
                firstParMargin
              />
            </TextInner>
          </TextWrapper>
        </Sizer>
      </Inner>
    </Wrapper>
  )
}

export default AccItem
