import React from 'react'
import styled from 'styled-components'
import remark from 'remark'
import remarkHTML from 'remark-html'

const Wrapper = styled.span`
  font-size: 18px;
  line-height: 26px;

  @media only screen and (min-width: 1024px) {
    font-size: 20px;
    line-height: 28px;
  }

  a {
    text-decoration: underline;
  }

  p:first-of-type {
    margin-bottom: ${({firstParMargin}) => firstParMargin ? '18px' : 0};
  }
`

const RichText = ({ content, markdown, firstParMargin }) => {
  return (
    <Wrapper
      firstParMargin={firstParMargin}
      dangerouslySetInnerHTML={{
        __html: markdown
          ? remark()
              .use(remarkHTML)
              .processSync(markdown)
              .toString()
              .replace('href=', 'target="_blank" href=')
          : `<span>${content}</span>`,
      }}
    ></Wrapper>
  )
}

export default RichText
