import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'

import LogoSvg from '../../assets/logo-full.svg'

import W from '../styled/Wrapper'
import P from '../styled/Paragraph'

const Wrapper = styled(W)`
  padding-top: 150px;
  padding-bottom: 113px;
  min-height: 100vh;
  display: grid;
  align-content: center;
  background-color: ${({theme}) => theme.white};

  @media only screen and (min-width: 1024px) {
    padding-bottom: 50px;
    top: 0;
    left: 0;
    right: 0;
    opacity: ${({ hideTop }) => (hideTop ? 0 : 1)};
    pointer-events: ${({ hideTop }) => (hideTop ? 'none' : 'auto')};
    max-width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;
    padding-top: 110px;
  }
`

const Logo = styled(LogoSvg)`
  display: block;
  width: 275px;
  height: auto;
  margin: 0 auto 120px;

  @media only screen and (min-width: 768px) {
    width: 452px;
    margin-bottom: 121px;
  }

  @media only screen and (min-width: 1024px) {
    width: 482px;
    margin-bottom: 91px;
  }
`

const Lead = styled(P)`
  margin-bottom: 50px;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 30px;
    max-width: 83%;
  }
`

const TextWrapper = styled.div`

@media only screen and (min-width: 768px) {
    width: 82%;
    margin-left: 18%;
  }
  @media only screen and (min-width: 1024px) {
    width: 75%;
    margin-left: 25%;
  }
`

const Text = styled(P)``

const Top = ({ lead, mainText }) => {
  const [hideTop, setHideTop] = useState(false)

  const handleScroll = () => {
    setHideTop(window.scrollY > window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <Fragment>
      <Wrapper as="section" hideTop={hideTop}>
        <Logo />
        <Lead size="l">{lead}</Lead>
        <TextWrapper>
          <Text size="m">{mainText}</Text>
        </TextWrapper>
      </Wrapper>
    </Fragment>
  )
}

export default Top
