import React from 'react'
import styled from 'styled-components'

import W from '../styled/Wrapper'
import H from '../styled/Heading'

import Workshop from './Workshop'

const Wrapper = styled(W)`
  padding-top: 135px;
  padding-bottom: 30px;
  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  position: relative;
  z-index: 2;

  @media only screen and (min-width: 1024px) {
    padding-top: 145px;
  }
`

const MaxWidth = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
`

const Title = styled(H)`
  text-align: center;
  margin-bottom: 110px;
`

const List = styled.ul`
  list-style: none;
  margin: 0;
`

const Workshops = ({ data, title, lead, text }) => {
  return (
    <Wrapper as="section">
      <MaxWidth>
        <Title size={1}>{title}</Title>
      </MaxWidth>

      <List>
        {data.map((item, i) => {
          return <Workshop key={i} data={item} inverse={(i + 1) % 2 === 0} />
        })}
      </List>
    </Wrapper>
  )
}

export default Workshops
