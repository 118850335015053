import React from 'react'
import styled from 'styled-components'
import ImgVideo from '../shared/ImgVideo'

import H from '../styled/Heading'
import P from '../styled/Paragraph'

const Wrapper = styled.li`
  padding-bottom: 90px;

  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    grid-template-areas: ${({ inverse }) =>
      inverse ? `'text image'` : `'image text'`};
    align-items: center;
    align-items: start;
    padding-bottom: 160px;
  }
`

const TextWrapper = styled.div`
  @media only screen and (min-width: 1024px) {
    grid-area: text;
    max-width: 540px;
  }
`

const Title = styled(H)`
  margin-bottom: 7px;
`

const Subtitle = styled(P)`
  margin-bottom: 15px;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 30px;
  }
`

const Text = styled(P)`
  margin-bottom: 50px;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 0px;
  }
`

const ImageWrapper = styled.div`
  grid-area: image;
  position: relative;

  @media only screen and (min-width: 768px) {
    max-width: 100%;
  }
`

const Caption = styled(P)`
  opacity: 0.6;
  padding-top: 10px;
  line-height: 1.3;

  @media only screen and (min-width: 768px) {
    width: 100%;
    text-align: center;
  }

  @media only screen and (min-width: 1024px) {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.15s ease-in-out;
    position: absolute;

    ${ImageWrapper}:hover & {
      opacity: 0.6;
      pointer-events: auto;
    }
  }
`

const Workshop = ({ data, inverse }) => {
  return (
    <Wrapper inverse={inverse}>
      <TextWrapper>
        <Title size={4}>{data.name}</Title>
        <Subtitle size={'xs'} sans uppercase bold>
          {data.location}
        </Subtitle>
        <Text size={'s'}>{data.about}</Text>
      </TextWrapper>
      <ImageWrapper>
        <ImgVideo fixedRatio full={data.videoSource} image={data.image} />
        <Caption size="xs" uppercase sans bold>
          {data.imageCaption}
        </Caption>
      </ImageWrapper>
    </Wrapper>
  )
}

export default Workshop
